import posthog from 'posthog-js'

export function analyticsTrackEvent(event, properties) {
  if (properties) {
    posthog.capture(event, properties)
  } else if (event) {
    posthog.capture(event)
  }
}

export function analyticsIdentify(userId, properties) {
  posthog.identify(userId, properties)
}

export function analyticsIdentifyReset() {
  posthog.reset()
}

export function analyticsSetIdentifyProperties(properties) {
  posthog.setPersonProperties(properties)
}

export function analyticsUpdateGroup(groupName, groupId, properties = {}) {
  posthog.group(groupName, groupId, properties)
}

export function analyticsOptOutCapturing() {
  posthog.opt_out_capturing()
}

export function analyticsOpInCapturing() {
  posthog.opt_in_capturing()
}

export function analyticsIsOptedInCapturing() {
  return posthog.has_opted_in_capturing()
}

export const ANALYTIC_EVENTS = {
  APP_OPEN: '[APP] Loaded (logged in)',

  PROJECT_OPEN: '[PROJ] Openened',
  PROJECT_CREATED: '[PROJ] Created',

  ITERATION_OPEN: '[ITER] Open details',
  ITERATION_OPEN_ERROR: '[ITER] Open error (check details)',
  ITERATION_CREATE: '[ITER] New iteration created',
  ITERATION_PAUSE: '[ITER] Pause iteration',
  ITERATION_RESUME: '[ITER] Resume iteration',

  ITERATION_ROLLBACK_NO_COMMENT: '[ITER] Rollback from action (no comment)',
  ITERATION_ROLLBACK_COMMENT: '[ITER] Rollback from action (with comment)',

  ITERATION_CLICK_PREVIEW: '[ITER] Click preview link',
  ITERATION_CLICK_PREVIEW_NO_LINK: '[ITER] Click preview link when link was not present',
  ITERATION_CLICK_REPO: '[ITER] Click repository link (iteration level)',
  ITERATION_CLICK_REPO_CHANGES: '[ITER] Click repository diff (iteration level)',
  ITERATION_CLICK_REPO_CHANGES_STEP: '[ITER] Click repositor diff link (step level)',
  ITERATION_CLICK_REPO_CHANGES_STEP_ACTION: '[ITER] Click repositor diff link (action level)',

  ITERATION_SWITCH_ITERATION: '[ITER] Switch iteration',
  ITERATION_STOP_VM: '[ITER] Stop VM - STOPPED',
  ITERATION_CODE_CHANGES_TOP: '[ITER] Click code changes link on iteration level',
  ITERATION_CODE_CHANGES_STEP: '[ITER] Click code changes link on step level',
  ITERATION_CODE_CHANGES_ACTION: '[ITER] Click code changes link on action level',

  ITERATION_FEEDBACK_ON_FINISHED: '[ITER] Ieration feedback received',

  ITERATION_EXPAND_STAGE: '[ITER] Expand stage',
  ITERATION_EXPAND_STAGE_STEP: '[ITER] Expand step in stage',
  ITERATION_ACTION_REVEAL: '[ITER] Action reveal output on list of actions',
  ITERATION_ACTION_DETAILS: '[ITER] Action details modal openened',

  AUTH_LOGIN_INIT: '[AUTH] Login initialized (email sent)',
  AUTH_LOGIN_SUCCESS: '[AUTH] Login success',
  AUTH_LOGIN_FAILURE: '[AUTH] Login failure',
  AUTH_SIGN_IN_EMAIL_NOT_FOUND: '[AUTH] Sign in email not found',
  AUTH_SIGN_IN_ERROR: '[AUTH] Sign in error (check details)',
  AUTH_LOGOUT: '[AUTH] Logout',
}
