import { useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import { Button } from '@/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog'

import { Description, ErrorMessage, Field, FieldGroup, Label } from '@/components/catalyst/fieldset'
import { Textarea } from '@/components/catalyst/textarea'

import { useToast } from '@/components/ui/use-toast'

import { createOrganization } from '@/services/Firebase'
import { Input } from '@/components/catalyst/input.jsx'
import { PlusCircleIcon, TrashIcon } from 'lucide-react'
import { TEMPLATE_WIDGET_TYPE } from '@/const/const.js'
import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox.jsx'
import { Checkbox, CheckboxField } from '@/components/catalyst/checkbox.jsx'

function useCreateOrganization() {
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)

  /**
   * Create a new organization.
   * @param {Object} options - The options for the function.
   * @param {String} options.name - The name of the organization.
   * @param {String} options.organization - The description of the organization.

   * @returns {Promise<Object>} A promise that resolves when the organization is created.
   */
  const createOrganizationFn = async payload => {
    if (isCreating) {
      return
    }
    setIsCreating(true)
    setError(null)
    try {
      const response = await createOrganization(payload)
      setResponse(response)
      return response
    } catch (error) {
      setError(error.message)
    } finally {
      setIsCreating(false)
    }
  }

  function resetCreateOrganizationFn() {
    setIsCreating(false)
    setError(null)
    setResponse(null)
  }

  return {
    response,
    isCreating,
    error,
    createOrganizationFn,
    resetCreateOrganizationFn,
  }
}

/**
 * A dialog for creating a new organization.
 * @param {Object} props - The props for the component.
 * @param {Boolean} props.isOpen - Whether the dialog is open.
 * @param {Function} props.onClose - A function to close the dialog.
 * @param {Function} props.onOrganizationCreated - A function to call when the organization is created.
 * @returns {JSX.Element} The JSX element for the component.
 *
 */
export default function MCreateUpdateOrganizationDialog({
  isOpen,
  onClose,
  onOrganizationCreated = () => {
    console.log('Default function called on MNewOrganizationDialog')
  },
}) {
  const {
    response,
    createOrganizationFn,
    error: organizationCreationError,
    isCreating,
    resetCreateOrganizationFn,
  } = useCreateOrganization()
  //
  const { toast } = useToast()

  const defaultValues = useMemo(() => {
    return {
      name: '',
      description: '',
      defaultTemplateName: '',
      defaultTemplateDescription: '',
      environmentVariables: [],
      usecase: {
        usecaseId: '',
        toolsets: '',
      },
    }
  }, [])

  const {
    register,
    control,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'environmentVariables',
  })

  const handleClose = useCallback(() => {
    resetForm()
    onClose()
  }, [onClose, resetForm])

  // handle post organization creation / error and closing the dialog
  useEffect(() => {
    if (response) {
      toast({
        title: 'Organization created successfully! 🎉',
        description: '',
      })
      onOrganizationCreated(response?.data || {})
      resetCreateOrganizationFn()
      handleClose()
    }
    if (organizationCreationError) {
      toast({
        variant: 'destructive',
        title: 'Error creating organization 😔',
        description: 'Check console for details and try again.',
      })
      console.error('Error creating organization:', organizationCreationError)
      resetCreateOrganizationFn()
    }
  }, [
    response,
    organizationCreationError,
    toast,
    resetCreateOrganizationFn,
    handleClose,
    onOrganizationCreated,
  ])

  // prepare form data for organization to be created
  const onSubmit = useCallback(
    data => {
      if (!isCreating) {
        createOrganizationFn({
          name: data?.name,
          description: data?.description,
          defaultTemplate: {
            name: data?.defaultTemplateName,
            description: data?.defaultTemplateDescription,
            iterationDefaultsTemplate: {
              environment: { fields: data?.environmentVariables },
              usecase: {
                fields: [
                  { key: 'usecaseId', value: data?.usecase?.usecaseId },
                  { key: 'toolsets', value: data?.usecase?.toolsets },
                ],
              },
            },
          },
        })
      }
    },
    [createOrganizationFn, isCreating]
  )

  return (
    <>
      <Dialog size="3xl" open={isOpen} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create new organization</DialogTitle>
          <DialogDescription>
            Create a new organization. You will be able to add teams and projects to it.
          </DialogDescription>
          <DialogBody>
            <FieldGroup className="space-y-8 pt-8">
              <div>
                <Field>
                  <Label>Name</Label>
                  <Input
                    {...register('name', {
                      required: 'Please fill in a name for the organization',
                    })}
                    invalid={!!errors.name}
                  />
                </Field>
                {errors.name && <ErrorMessage className="">{errors.name.message}</ErrorMessage>}
              </div>

              <div>
                <Field>
                  <Label>Description</Label>
                  <Textarea
                    {...register('description', {})}
                    invalid={!!errors.description}
                    rows={10}
                  />
                </Field>
                {errors.description && (
                  <ErrorMessage className="pt-0">{errors.description.message}</ErrorMessage>
                )}
              </div>
            </FieldGroup>

            <Description className=" mt-8 border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
              Add a default template for the organization
            </Description>
            <FieldGroup className="space-y-8 pt-8">
              <div>
                <Field>
                  <Label>Name for default template</Label>
                  <Input
                    {...register('defaultTemplateName', {
                      required: 'Please fill in a name for default template',
                    })}
                    invalid={!!errors.defaultTemplateName}
                  />
                </Field>
                {errors.defaultTemplateName && (
                  <ErrorMessage className="">{errors.defaultTemplateName.message}</ErrorMessage>
                )}
              </div>

              <div>
                <Field>
                  <Label>Description for default template</Label>
                  <Textarea
                    {...register('defaultTemplateDescription', {})}
                    invalid={!!errors.defaultTemplateDescription}
                    rows={10}
                  />
                </Field>
                {errors.defaultTemplateDescription && (
                  <ErrorMessage className="pt-0">
                    {errors.defaultTemplateDescription.message}
                  </ErrorMessage>
                )}
              </div>
            </FieldGroup>

            <Description className=" mt-8 border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
              Add usecase
            </Description>
            <FieldGroup className="space-y-8 pt-8">
              <div>
                <Field>
                  <Label>Usecase ID</Label>
                  <Input
                    {...register('usecase.usecaseId', {
                      required: 'Please fill in the id of the usecase',
                    })}
                    invalid={!!errors.usecase?.usecaseId}
                  />
                </Field>
                {errors.usecase?.usecaseId && (
                  <ErrorMessage className="">{errors.usecase?.usecaseId.message}</ErrorMessage>
                )}
              </div>
              <div>
                <Field>
                  <Label>Toolsets</Label>
                  <Input
                    {...register('usecase.toolsets', {
                      required: 'Please fill in the id of the toolsets',
                    })}
                    invalid={!!errors.usecase?.toolsets}
                  />
                </Field>
                {errors.usecase?.toolsets && (
                  <ErrorMessage className="">{errors.usecase?.toolsets.message}</ErrorMessage>
                )}
              </div>
            </FieldGroup>

            <Description className=" mt-8 border-b border-zinc-200 pb-2 font-mono font-bold text-zinc-700">
              Add environment variables for default template
            </Description>
            <FieldGroup className="mt-8 space-y-8   pb-12 sm:space-y-0 sm:pb-0">
              {fields.map((env, index) => (
                <div
                  className="sm:grid sm:grid-cols-12 sm:items-start sm:gap-4 sm:py-4"
                  key={index}
                >
                  <div className="border border-zinc-200 p-4 sm:col-span-11 sm:mt-0">
                    <Description className="mb-4   pb-2 font-mono font-bold text-zinc-700">
                      New environment variable
                    </Description>
                    <Field className="">
                      <Label>Name</Label>
                      <Input
                        {...register(`environmentVariables.${index}.name`, {
                          required: 'Please fill in a name',
                        })}
                        placeholder="Name of the variable"
                        invalid={!!errors?.environmentVariables?.[index]?.name}
                      />
                      {errors?.environmentVariables?.[index]?.name && (
                        <ErrorMessage className="pt-0">
                          {errors?.environmentVariables?.[index]?.name.message}
                        </ErrorMessage>
                      )}
                    </Field>
                    <Field className="pt-2">
                      <Label>Group</Label>
                      <Input
                        {...register(`environmentVariables.${index}.group`, {
                          required: 'Please fill in a group',
                        })}
                        placeholder="Group of the variable"
                        invalid={!!errors?.environmentVariables?.[index]?.group}
                      />
                      {errors?.environmentVariables?.[index]?.name && (
                        <ErrorMessage className="pt-0">
                          {errors?.environmentVariables?.[index]?.group.message}
                        </ErrorMessage>
                      )}
                    </Field>
                    <Field className="pt-2">
                      <Label>Key</Label>
                      <Input
                        {...register(`environmentVariables.${index}.key`, {
                          required: 'Please fill in a key',
                        })}
                        placeholder="Key name"
                        invalid={!!errors?.environmentVariables?.[index]?.key}
                      />
                      {errors?.environmentVariables?.[index]?.key && (
                        <ErrorMessage className="pt-0">
                          {errors?.environmentVariables?.[index]?.key.message}
                        </ErrorMessage>
                      )}
                    </Field>
                    <Field className="pt-2">
                      <Label>Description</Label>
                      <Textarea
                        {...register(`environmentVariables.${index}.description`, {})}
                        placeholder="Description"
                        inputClassName="redacted-input focus:display-redacted-input"
                        invalid={!!errors?.environmentVariables?.[index]?.description}
                      />
                      {errors?.environmentVariables?.[index]?.description && (
                        <ErrorMessage className="pt-0">
                          {errors?.environmentVariables?.[index]?.description.message}
                        </ErrorMessage>
                      )}
                    </Field>
                    <Field className="pt-2 ">
                      <Label>Placeholder</Label>
                      <Input
                        {...register(`environmentVariables.${index}.placeholder`, {})}
                        placeholder="Placeholder"
                        invalid={!!errors?.environmentVariables?.[index]?.placeholder}
                      />
                      {errors?.environmentVariables?.[index]?.placeholder && (
                        <ErrorMessage className="pt-0">
                          {errors?.environmentVariables?.[index]?.placeholder.message}
                        </ErrorMessage>
                      )}
                    </Field>
                    <Field className="mt-4 flex items-center justify-between">
                      <Label>Widget type</Label>
                      <Controller
                        control={control}
                        name={`environmentVariables.${index}.type`}
                        render={({ field: { onChange, value } }) => (
                          <Listbox onChange={onChange} value={value} className="max-w-48">
                            <ListboxOption
                              key={TEMPLATE_WIDGET_TYPE.TEXT_SHORT}
                              value={TEMPLATE_WIDGET_TYPE.TEXT_SHORT}
                            >
                              <ListboxLabel className="capitalize">Short text</ListboxLabel>
                            </ListboxOption>
                            <ListboxOption
                              key={TEMPLATE_WIDGET_TYPE.TEXT_LONG}
                              value={TEMPLATE_WIDGET_TYPE.TEXT_LONG}
                            >
                              <ListboxLabel className="capitalize">Long text</ListboxLabel>
                            </ListboxOption>
                          </Listbox>
                        )}
                      />
                    </Field>
                    <div className="mt-4 flex items-center gap-5">
                      <Field className=" flex items-center ">
                        <Label>Is required</Label>
                        <Controller
                          control={control}
                          name={`environmentVariables.${index}.required`}
                          render={({ field: { onChange, value } }) => (
                            <CheckboxField className="ml-4">
                              <Checkbox name="required" checked={value} onChange={onChange} />
                            </CheckboxField>
                          )}
                        />
                      </Field>
                      <Field className="flex items-center ">
                        <Label>{"Don't encrypt"}</Label>
                        <Controller
                          control={control}
                          name={`environmentVariables.${index}.dontEncrypt`}
                          render={({ field: { onChange, value } }) => (
                            <CheckboxField className="ml-4">
                              <Checkbox name="dontEncrypt" checked={value} onChange={onChange} />
                            </CheckboxField>
                          )}
                        />
                      </Field>
                    </div>
                  </div>

                  <div className="just flex h-full w-full items-center justify-start sm:col-span-1 sm:mt-0">
                    <Button color="red" onClick={() => remove(index)}>
                      <TrashIcon />
                    </Button>
                  </div>
                </div>
              ))}
              <Button
                type="button"
                onClick={() =>
                  append({
                    name: '',
                    key: '',
                    description: '',
                    required: false,
                    placeholder: '',
                    type: TEMPLATE_WIDGET_TYPE.TEXT_SHORT,
                  })
                }
              >
                <PlusCircleIcon />
                Add Env
              </Button>
            </FieldGroup>
          </DialogBody>
          <DialogActions>
            <Button disabled={isCreating} plain onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={isCreating} className={isCreating ? 'bg-zinc-600' : ''} type="submit">
              {isCreating ? 'Working...' : 'Create Organization'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

MCreateUpdateOrganizationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOrganizationCreated: PropTypes.func,
}
