import { useMemo } from 'react'

import { contentShape } from '@/components/propTypes.js'

export default function AActionContentDescription({ content }) {
  let contentTexts = useMemo(() => {
    const safeContent = content || ''
    let arrToReturn = []
    if (typeof safeContent === 'string') {
      arrToReturn = [safeContent]
    } else {
      arrToReturn = safeContent
    }
    return arrToReturn
  }, [content])
  return (
    <>
      {contentTexts.map((content, index) => {
        return <div key={index}>{content}</div>
      })}
    </>
  )
}

AActionContentDescription.propTypes = { content: contentShape }
