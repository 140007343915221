import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { AcademicCapIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function ReflectorAction({ action, isLast = false }) {
  const reflection =
    action?.args?.reflection?.[0]?.['description'] ||
    action?.args?.Reflection ||
    action?.args?.reflection

  const advices = action?.args?.advices || 'No advices provided.'
  // if action?.args?.plan is an array

  let plan = 'No plan provided.'
  if (Array.isArray(action?.args?.plan)) {
    plan = ''
    action.args.plan.forEach((el, index) => {
      plan = plan + `* ${index + 1}. ${el?.description} (call_step_done: ${el?.call_step_done})\n`
    })
  } else if (typeof action?.args?.plan === 'string' || typeof action?.args?.Plan === 'string') {
    plan = action?.args?.Plan || action?.args?.plan
  }

  const hints = action?.args?.Hints || action?.args?.hints || 'No hints provided.'

  return (
    <GenericAction
      action={action}
      detailsContent={`## REFLECTION:\n${reflection}\n\n## Plan:\n${plan}\n\n## ADVICES:\n${advices}\n\n## Hints:\n${hints}\n\n`}
      actionIcon={AcademicCapIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Reflector" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={reflection?.description || reflection || action.name} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

ReflectorAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
