import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/16/solid'

import { Button } from '@/components/catalyst/button'

import { Badge } from '@/components/catalyst/badge'

import MStepsList from '@/components/molecules/iteration-details/MStepsList'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

export function MElementListItem({
  element,
  lastTick = Date.now(),
  isLast = false,
  expandAllDetails = false,
  filterOutLLMActions = true,
}) {
  const steps = useMemo(() => {
    if (!element?.steps) {
      return null
    }

    const steps = Object.entries(element?.steps).map(([key, value]) => {
      return { stepId: key, ...value, repoURI: element.repoURI }
    })
    return steps.sort((a, b) => a.index - b.index)
  }, [element])

  const isDone = useMemo(() => {
    return element.status === 'DONE'
  }, [element])

  const isRunning = useMemo(() => {
    return element.status === 'RUNNING'
  }, [element])

  const isAwaiting = useMemo(() => {
    return element.status === 'NEW'
  }, [element])

  const elementStack = useMemo(() => {
    if (Array.isArray(element?.stack)) {
      return element.stack.join(', ')
    } else {
      return element.stack
    }
  }, [element])

  const [showSteps, setShowSteps] = useState(expandAllDetails || !isDone)

  const handleShowAllSteps = useCallback(() => {
    if (!showSteps) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXPAND_STAGE, {
        iterationId: element?.iterationId,
        elementIndex: element?.index,
        elementStatus: element?.status || 'N/A',
      })
    }

    setShowSteps(!showSteps)
  }, [showSteps, element])

  return (
    <li key={element.elementId} className="relative flex gap-x-4">
      <div
        className={classNames(
          isLast ? 'h-6' : '-bottom-6',
          'absolute left-0 top-0 flex w-6 justify-center '
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>

      <div className="relative flex h-6 w-6 flex-none items-center justify-center  ">
        {isDone && (
          <CheckCircleIcon
            className="h-6 w-6 rounded-full bg-gray-100 text-emerald-500"
            aria-hidden="true"
          />
        )}
        {isRunning && (
          <div className="h-4 w-4 animate-pulse rounded-full bg-sky-400 ring-1  ring-gray-300" />
        )}
        {isAwaiting && <div className="h-4 w-4 rounded-full bg-zinc-400 ring-1 ring-gray-300" />}
      </div>
      <div
        className={classNames(
          showSteps && steps?.length > 0 ? 'bg-neutral-100 shadow-inner' : 'bg-white shadow-md',
          'w-3/12 flex-auto flex-shrink-0 flex-grow-0 rounded-md p-3 ring-1 ring-inset ring-gray-200'
        )}
      >
        <Badge color="zinc" className="mb-2 font-mono">
          Stage {element.index}
        </Badge>

        <div className="flex justify-between gap-x-4">
          <div className="flex flex-col py-0.5 font-mono text-lg leading-6 text-zinc-800">
            <span className="font-bold text-zinc-900">{element.name}</span>
            <p className="pt-1 font-sans text-sm">{elementStack}</p>
          </div>

          <time
            dateTime={element.updatedAtRelative}
            className="flex-none py-0.5 text-xs leading-5 text-zinc-300"
          >
            {element.updatedAtRelative}
          </time>
        </div>
        <p className="pt-2 text-sm leading-6 text-zinc-700">{element.description}</p>
        {!!steps && !!steps?.length && (
          <div className="border-t? mt-4 flex w-full items-center justify-between border-zinc-200 pt-2 ">
            <div className="text-sm font-bold text-zinc-800">
              {steps.length > 1 ? `${steps.length} Steps` : `${steps.length} Step`}
            </div>
            <Button plain onClick={handleShowAllSteps}>
              {showSteps ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </Button>
          </div>
        )}
      </div>

      {!!steps && !!showSteps && (
        <div className="max-w-9/12 w-9/12 flex-grow">
          <MStepsList
            steps={steps}
            lastTick={lastTick}
            expandAllDetails={expandAllDetails}
            filterOutLLMActions={filterOutLLMActions}
          />
        </div>
      )}
    </li>
  )
}

export default function MElementsList({
  elements,
  lastTick = Date.now(),
  expandAllDetails = false,
  filterOutLLMActions = true,
}) {
  return (
    <ul role="list" className="space-y-6">
      {elements?.map((element, index) => (
        <MElementListItem
          key={element.id}
          element={element}
          lastTick={lastTick}
          isLast={index === elements.length - 1}
          expandAllDetails={expandAllDetails}
          filterOutLLMActions={filterOutLLMActions}
        />
      ))}
    </ul>
  )
}

MElementsList.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      updatedAtRelative: PropTypes.string,
      stack: PropTypes.arrayOf(PropTypes.string),
      steps: PropTypes.object,
      elementId: PropTypes.string,
      index: PropTypes.number,
      repoURI: PropTypes.string,
      iterationId: PropTypes.string,
    })
  ),
  lastTick: PropTypes.number,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}

MElementListItem.propTypes = {
  element: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedAtRelative: PropTypes.string,
    stack: PropTypes.arrayOf(PropTypes.string),
    steps: PropTypes.object,
    elementId: PropTypes.string,
    index: PropTypes.number,
    repoURI: PropTypes.string,
    iterationId: PropTypes.string,
  }),
  lastTick: PropTypes.number,
  isLast: PropTypes.bool,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}
