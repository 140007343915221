import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import useStore from '@/stores/useStore'

import OPageWrapper from '@/components/organisms/OPageWrapper'
import OProjectsTable from '@/components/organisms/OProjectsTable'
import OTeamsTable from '@/components/organisms/OTeamsTable'
import MCardTableSkeleton from '@/components/molecules/MCardTableSkeleton'
import { CreateButton } from '@/components/atoms/ACreateButton.jsx'
import MNewProjectDialog from '@/components/molecules/MCreateNewProject.jsx'

export default function POrganizationDetails() {
  const { organizationId } = useParams()
  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false)
  const projects = useStore(state => state.projects)
  const teams = useStore(state => state.teams)
  const getOrgById = useStore(state => state.getOrgById)
  const getProjectsByOrgId = useStore(state => state.getProjectsByOrgId)
  const organizations = useStore(state => state.organizations)
  const getTeamsByOrgId = useStore(state => state.getTeamsByOrgId)

  const organizationProjects = useMemo(() => {
    if (projects.length > 0) {
      return getProjectsByOrgId(organizationId)
    }
    return null
  }, [projects, getProjectsByOrgId, organizationId])

  const { isLoading, organization } = useMemo(() => {
    if (organizations.length === 0) {
      return { isLoading: true, organization: null }
    }
    console.log({ isLoading: false, organization: getOrgById(organizationId) })
    return { isLoading: false, organization: getOrgById(organizationId) }
  }, [organizations, getOrgById, organizationId])

  const organizationTeams = useMemo(() => {
    if (teams.length > 0) {
      return getTeamsByOrgId(organizationId)
    }
    return null
  }, [teams, getTeamsByOrgId, organizationId])

  const breadcrumbs = useMemo(() => {
    if (organization) {
      return [
        {
          name: organization.name,
          href: `/organizations/${organization.id}`,
          current: true,
        },
      ]
    }
    return []
  }, [organization])

  const handleAddNewProject = useCallback(() => {
    if (Object.keys(organization?.templates || {}).length === 0) {
      alert('No templates found for this organization')
    } else {
      setIsNewProjectDialogOpen(true)
    }
  }, [])

  // :TODO: This needs to ne fixed - we need to know if the organization is not there
  // the problem now is that organization is still null when organizations.length === 0 is true
  // that's caused by different roles loading different data at different times
  if (isLoading || !organization) {
    return (
      <OPageWrapper breadcrumbs={breadcrumbs}>
        <div role="status" className="px-2 py-6 lg:px-24 lg:py-12 ">
          <div className="pb-8 pt-12">
            <h1 className="animate-pulse text-4xl font-semibold leading-6 text-gray-900">
              {organization?.name || 'Organization...'}
            </h1>
          </div>
          <MCardTableSkeleton
            title="Loading projects..."
            howManyProjectsToFake={7}
            labels={['Name', 'Team', 'Updated', 'Status']}
          />

          <MCardTableSkeleton
            title="Loading teams..."
            howManyProjectsToFake={7}
            labels={['Name', 'Project count', 'Your access', 'Actions']}
          />
        </div>
      </OPageWrapper>
    )
  }

  return (
    <OPageWrapper breadcrumbs={breadcrumbs}>
      <div className="px-2 py-6 lg:px-24 lg:py-12 ">
        <div className="pb-8 pt-12">
          <h1 className="text-4xl font-semibold leading-6 text-gray-900">{organization?.name}</h1>
        </div>
        <div className="overflow-hidden rounded-lg border border-gray-200 bg-white px-4 py-5 shadow-lg sm:px-6">
          <div className="-ml-4 -mt-2  flex flex-wrap items-center justify-between pb-6 pt-2 sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-xl font-semibold leading-6 text-gray-900">Projects</h3>
            </div>
            <div className="ml-4 mt-2 flex flex-shrink-0 items-center justify-center">
              <CreateButton onClick={handleAddNewProject} label="Create new project" />
            </div>
          </div>
          <OProjectsTable projects={organizationProjects} />
        </div>
        <div className="mt-12 overflow-hidden rounded-lg border border-gray-200 bg-white px-4 py-5 shadow-lg sm:px-6">
          <div className="-ml-4 -mt-2  flex flex-wrap items-center justify-between pb-6 pt-2 sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-xl font-semibold leading-6 text-gray-900">Teams</h3>
            </div>
            {/* <div className="ml-4 mt-2 flex flex-shrink-0 items-center justify-center">
              <button
                type="button"
                onClick={() => {
                  alert('Not implemented yet')
                }}
                className="relative inline-flex items-center rounded-md bg-mimirBlue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-mimirBlue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mimirBlue-500"
              >
                Create new team
              </button>
            </div> */}
          </div>
          <OTeamsTable teams={organizationTeams} />
        </div>
      </div>
      {isNewProjectDialogOpen && (
        <MNewProjectDialog
          organizationId={organization?.id}
          templates={organization.templates}
          isOpen={isNewProjectDialogOpen}
          onClose={() => {
            setIsNewProjectDialogOpen(false)
          }}
          onSubmitted={data => {
            console.log('data received from organization dialog', { data })
          }}
        />
      )}
    </OPageWrapper>
  )
}

POrganizationDetails.propTypes = {}
