import React, { useCallback, useMemo, useState } from 'react'
import PropTypes, { element } from 'prop-types'
import classNames from 'classnames'
import { cloneDeep } from 'lodash'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid'

import { Button } from '@/components/catalyst/button'

import { Badge } from '@/components/catalyst/badge'

import MActionsList from '@/components/molecules/iteration-details/MActionsList'

import { STEP_STATUSES } from '@/const/const'
import { convertGitHubSSHToHTTPS, getCommitHashes, hasValidCommitHashes } from '@/lib/git-utils.js'
import { GitCompareIcon, GitPullRequestArrow } from 'lucide-react'
import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

const STEP_TYPE_LABELS = {
  research: '📖 Researcher',
  implementation: '🧑‍💻 Implementer',
  troubleshooting: '🧪 Troubleshooter',
  test: '🧪 Tester',
  documentation: '📝 Documenter',
  default: '📝 Agent',
}

export function MStepsListItem({
  step,
  isLast = false,
  expandAllDetails = false,
  filterOutLLMActions = false,
}) {
  const [showActions, setShowActions] = useState(expandAllDetails || step?.status !== 'DONE')

  const actions = useMemo(() => {
    if (!step?.actions) {
      return null
    }

    let actions = []
    if (filterOutLLMActions) {
      Object.entries(step?.actions).forEach(([key, value]) => {
        if (value.type === 'llm' || value.type === 'hints') {
          return
        }
        const action = { actionId: key, ...value, repoURI: step.repoURI }
        actions.push(action)
      })
    } else {
      actions = Object.entries(step?.actions).map(([key, value]) => {
        return { actionId: key, ...value, repoURI: step.repoURI }
      })
    }

    actions = actions.sort((a, b) => a.index - b.index)
    // remove llm & hint actions
    // actions = actions.filter(action => action.type !== 'llm' && action.type !== 'hints')
    return actions
  }, [step, filterOutLLMActions])

  const actionsWithSTDOUTCombined = useMemo(() => {
    // go over all actions. If action.subtype is `async_function_output`
    // add action.outputs.stdout to the action.terminal_outputs [] where actionId equals action.args.sourceActionId
    // don't mutate the original actions array and don't add async_function_output to filtered

    if (!actions) {
      return []
    }

    const filtered = cloneDeep(actions.filter(action => action.subtype !== 'async_function_output'))
    const asyncFunctionOutputs = actions.filter(
      action => action.subtype === 'async_function_output'
    )

    asyncFunctionOutputs.forEach(asyncFunctionOutput => {
      const action = filtered.find(
        action => action.actionId === asyncFunctionOutput?.args?.sourceActionId
      )
      if (action) {
        if (!action.terminal_outputs) {
          action.terminal_outputs = []
        }
        asyncFunctionOutput?.outputs.forEach(output => {
          action.terminal_outputs.push(output?.stdout || 'Std out missing')
        })
      }
    })
    return filtered
  }, [actions])

  const commitHashes = useMemo(() => {
    return getCommitHashes(actionsWithSTDOUTCombined)
  }, [actionsWithSTDOUTCombined])

  const isDone = useMemo(() => {
    return step.status === STEP_STATUSES.DONE
  }, [step])

  const isAwaiting = useMemo(() => {
    return step.status === STEP_STATUSES.AWAITING
  }, [step])

  const isRunning = useMemo(() => {
    return step.status === STEP_STATUSES.RUNNING
  }, [step])

  const stepAgentLabel = useMemo(() => {
    return STEP_TYPE_LABELS[step?.type] || STEP_TYPE_LABELS['default']
  }, [step?.type])

  const handleShowActions = useCallback(() => {
    if (!showActions) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_EXPAND_STAGE_STEP, {
        iterationId: step?.iterationId,
        elementIndex: step?.elementIndex,
        stepIndex: step?.index,
        stepStatus: step?.status || 'N/A',
      })
    }
    setShowActions(!showActions)
  }, [showActions, step])

  return (
    <li key={step.stepId} className="relative flex gap-x-4">
      <div
        className={classNames(
          isLast ? 'h-6' : '-bottom-6',
          'absolute left-0 top-0 flex w-6 justify-center '
        )}
      >
        <div className="w-px bg-gray-300 " />
      </div>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center">
        {isDone && (
          <CheckCircleIcon
            className="h-5 w-5 rounded-full bg-gray-200 text-emerald-500"
            aria-hidden="true"
          />
        )}
        {isRunning && (
          <div className="h-3 w-3 animate-pulse rounded-full bg-sky-400 ring-1 ring-gray-300" />
        )}
        {isAwaiting && <div className="h-3 w-3 rounded-full bg-zinc-400 ring-1 ring-gray-300" />}
      </div>
      <div
        className={classNames(
          'w-full rounded-md bg-white p-3 ring-1 ring-inset ring-gray-200',
          showActions && 'shadow-inner',
          !showActions && 'shadow-sm'
        )}
      >
        <div className="flex items-baseline justify-between gap-x-4">
          <div className="pb-2 pt-1 text-sm leading-5 text-gray-500">
            <Badge color="zinc" className="font-mono">
              Step {step.index}
            </Badge>
          </div>
          <div className="font-mono text-sm tracking-normal text-zinc-500">{stepAgentLabel}</div>

          {/* <time
            dateTime={step.updatedAtRelative}
            className="flex-none py-0.5 text-xs leading-5 text-gray-500"
          >
            {step.updatedAtRelative}
          </time> */}
        </div>
        <p className="mt-2 text-sm leading-6 text-gray-500 ">{step.description}</p>

        {!!actionsWithSTDOUTCombined && !!actionsWithSTDOUTCombined?.length && (
          <div
            className="border-gray-200pt-2 mt-4 flex w-full items-center justify-between border-t pt-2 hover:cursor-pointer"
            onClick={handleShowActions}
          >
            <div className="text-xs font-bold">
              {actionsWithSTDOUTCombined.length > 1
                ? `${actionsWithSTDOUTCombined.length} Actions executed`
                : `${actionsWithSTDOUTCombined.length} Action executed`}
            </div>
            <Button
              plain
              onClick={() => setShowActions(!showActions)}
              className="hover:cursor-pointer"
            >
              {showActions ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Button>
          </div>
        )}
        {!!actionsWithSTDOUTCombined && showActions && (
          <div className="mt-4">
            {hasValidCommitHashes(commitHashes) && (
              <div className="mb-8 mt-8 flex flex-1 items-center justify-center ">
                <Button
                  href={`${convertGitHubSSHToHTTPS(step?.repoURI)}/compare/${commitHashes?.startCommitHash}...${commitHashes?.endCommitHash}`}
                  onClick={() => {
                    analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO_CHANGES_STEP, {
                      iterationId: step?.iterationId,
                      elementIndex: step?.elementIndex,
                      stepIndex: step?.index,
                      stepStatus: step?.status || 'N/A',
                    })
                  }}
                  target="_blank"
                  outline
                  className="place-self-start hover:cursor-pointer"
                >
                  <GitCompareIcon strokeWidth={3} className="mr-1 h-6 w-4 text-zinc-600" />
                  <span className="font-mono text-sm font-normal tracking-normal text-zinc-600">
                    See all code changes in this step
                  </span>
                </Button>
              </div>
            )}
            <MActionsList actions={actionsWithSTDOUTCombined} expandAllDetails={expandAllDetails} />
          </div>
        )}
      </div>
    </li>
  )
}

export default function MStepsList({
  steps,
  expandAllDetails = false,
  filterOutLLMActions = true,
}) {
  return (
    <ul role="list" className="space-y-6">
      {steps?.map((step, index) => (
        <MStepsListItem
          key={step.stepId}
          step={step}
          isLast={index === steps.length - 1}
          expandAllDetails={expandAllDetails}
          filterOutLLMActions={filterOutLLMActions}
        />
      ))}
    </ul>
  )
}

MStepsList.propTypes = {
  steps: PropTypes.array,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}

MStepsListItem.propTypes = {
  step: PropTypes.object,
  isLast: PropTypes.bool,
  expandAllDetails: PropTypes.bool,
  filterOutLLMActions: PropTypes.bool,
}
