import { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Timestamp } from 'firebase/firestore'

import { getIteration } from '@/services/Firebase'

import { Button } from '@/components/catalyst/button'

import { Listbox, ListboxLabel, ListboxOption } from '@/components/catalyst/listbox'

import MElementsList from '@/components/molecules/iteration-details/MElementsList'

import OiterationDetailsSkeleton from '@/components/organisms/project-details/OiterationDetailsSkeleton'
import MNewIterationDialog from '@/components/molecules/iteration-details/MNewIterationDialog'
import MStartStopIterationCard from '@/components/molecules/iteration-details/MStartStopIterationCard'
import MIterationStatusChangeAwaiting from '@/components/molecules/iteration-details/MIterationStatusChangeAwaiting'

import useStore from '@/stores/useStore'
import { useToast } from '@/components/ui/use-toast'
import { isSuperadmin as isSuperAdminFn } from '@/lib/acl-utils'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

const SHOW_ALL_DETAILS_BY_DEFAULT = false

/**
 * A component for displaying details of an iteration.
 * @param {Object} props - The props for the component.
 * @param {Object} props.iterationMeta - The iteration to display.
 * @param {Boolean} props.isNewestIteration - Whether the iteration is the newest one.
 * @param {Number} props.lastTick - The last tick time.
 * @param {Function} props.onIterationCreated - The function to call when a new iteration is created.
 * @returns {JSX.Element} The JSX element for the component.
 */
export default function OIterationDetails({
  iterationMeta,
  isNewestIteration = false,
  lastTick = Date.now(),
  onIterationCreated,
}) {
  const [isNewIterationDialogOpen, setIsNewIterationDialogOpen] = useState(false)
  const [lastIterationMeta, setLastIterationMeta] = useState(iterationMeta)
  const [iteration, setIteration] = useState({})
  const [isIterationLoading, setIsIterationLoading] = useState(true)
  const [iterationLoadingError, setIterationLoadingError] = useState(null)
  const [filterOutLLMActions, setFilterOutLLMActions] = useState(true)
  const userRoles = useStore(state => state.userRoles)
  const { toast } = useToast()

  const [isAwaingCommand, isWorkingOnStatusChange] = useMemo(() => {
    const isAwaiting = iterationMeta?.awaitingCommand ? true : false
    const isDuringTransition = iterationMeta?.awaitingCommand?.active ? true : false
    return [isAwaiting, isDuringTransition]
  }, [iterationMeta])

  const isSuperAdmin = useMemo(() => {
    return isSuperAdminFn(userRoles)
  }, [userRoles])

  useEffect(() => {
    if (!_.isEqual(iterationMeta, lastIterationMeta)) {
      setLastIterationMeta(iterationMeta)
    }
  }, [iterationMeta, lastIterationMeta])

  useEffect(() => {
    if (iterationMeta?.id !== lastIterationMeta?.id) {
      // only set loading if the iteration id has changed to limit flickering on loading
      setIsIterationLoading(true)
    }
  }, [iterationMeta, lastIterationMeta])

  useEffect(() => {
    if (lastIterationMeta) {
      getIteration({ iterationId: lastIterationMeta.id })
        .then(iterationData => {
          setIteration(iterationData.data)
          setIsIterationLoading(false)
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN, {
            iterationId: lastIterationMeta?.id,
            projectId: lastIterationMeta?.projectId || 'N/A',
            teamId: lastIterationMeta?.teamId || 'N/A',
            organizationId: lastIterationMeta?.organizationId || 'N/A',
            iterationStatus: lastIterationMeta.status,
            vmStatus: lastIterationMeta.vmStatus || 'N/A',
          })
        })
        .catch(err => {
          console.error('Error getting iteration', err)
          setIterationLoadingError(err)
          setIsIterationLoading(false)
          analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_OPEN_ERROR, {
            iterationId: lastIterationMeta?.id,
            projectId: lastIterationMeta?.projectId || 'N/A',
            teamId: lastIterationMeta?.teamId || 'N/A',
            organizationId: lastIterationMeta?.organizationId || 'N/A',
            iterationStatus: lastIterationMeta.status,
            vmStatus: lastIterationMeta.vmStatus || 'N/A',
            error: err.message,
          })
          toast({
            variant: 'destructive',
            title: 'Error loading iteration details 😔',
            description: 'Try refreshing the page or contact Proofs team.',
          })
        })
    }
  }, [lastIterationMeta, toast])

  const elements = useMemo(() => {
    const elements = Object.entries(iteration?.elements || {}).map(([key, value]) => {
      return { id: key, ...value }
    })
    // also order elements by index
    return elements.sort((a, b) => a.index - b.index)
  }, [iteration])

  const elementsWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (lastTick && elements) {
      return elements?.map(element => {
        const dateCreated = new Timestamp(element?.createdAt)?.toDate() || Date.now()
        const dateUpdated = new Timestamp(element?.updatedAt)?.toDate() || Date.now()
        return {
          ...element,
          updatedAt: dateUpdated,
          createdAt: dateCreated,
          updatedAtRelative: DateTime.fromJSDate(dateUpdated).toRelative(),
          repoURI: iteration?.repoURI,
        }
      })
    }
  }, [elements, iteration?.repoURI, lastTick])

  const isDone = useMemo(() => {
    return iteration.status === 'DONE'
  }, [iteration])

  const canCreateNewIteration = useMemo(() => {
    return isDone
  }, [isDone])

  // if iteration status is not done, scroll the page to the bottom on iteration updates
  // also do this only if the user is scrolled to the bottom
  // Adding scrollContainer.current as a dependency

  if (isIterationLoading) {
    return <OiterationDetailsSkeleton />
  }

  return (
    <>
      {isSuperAdmin && (
        <div className="flex w-full justify-end pt-4">
          <Listbox
            onChange={e => {
              setFilterOutLLMActions(e)
            }}
            value={filterOutLLMActions}
            className="max-w-48"
          >
            <ListboxOption key="filterOutLLMActions-true" value={true}>
              <ListboxLabel>Hide LLM actions</ListboxLabel>
            </ListboxOption>
            <ListboxOption key="filterOutLLMActions-false" value={false}>
              <ListboxLabel>Show LLM actions</ListboxLabel>
            </ListboxOption>
          </Listbox>
        </div>
      )}
      <div className="pt-4">
        <MElementsList
          elements={elementsWithRelativeTime}
          expandAllDetails={SHOW_ALL_DETAILS_BY_DEFAULT}
          filterOutLLMActions={filterOutLLMActions}
        />
      </div>
      {/* {isNewestIteration && (
        <>
          <div className="mt-12 flex items-center justify-center">
            <Button
              disabled={!canCreateNewIteration}
              onClick={() => {
                setIsNewIterationDialogOpen(true)
              }}
              className={!canCreateNewIteration ? 'bg-zinc-600 hover:cursor-wait' : ''}
            >
              {!canCreateNewIteration ? 'Working....' : 'Create new iteration from this point'}
            </Button>
          </div>
          <MNewIterationDialog
            projectId={iteration?.projectId}
            isOpen={isNewIterationDialogOpen}
            onClose={() => {
              setIsNewIterationDialogOpen(false)
            }}
            environment={iteration?.environment}
            environmentId={iteration?.environmentId}
            sourceIterationData={iteration}
            isFirstIterationForProject={false}
            onIterationCreated={onIterationCreated}
          />
        </>
      )} */}
      <div className="mb-24"></div>

      {!isAwaingCommand && <MStartStopIterationCard iterationMeta={iterationMeta} />}
      {isAwaingCommand && (
        <MIterationStatusChangeAwaiting
          iterationId={iterationMeta?.id}
          isOpen={isAwaingCommand}
          awaitingCommandMeta={iterationMeta?.awaitingCommand || {}}
        />
      )}
    </>
  )
}

OIterationDetails.propTypes = {
  iterationMeta: PropTypes.shape({
    iterationId: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    inputTokens: PropTypes.number,
    outputTokens: PropTypes.number,
    elapsedTime: PropTypes.number,
    prompt: PropTypes.string,
    createdAt: PropTypes.instanceOf(Timestamp),
    updatedAt: PropTypes.instanceOf(Timestamp),
    elements: PropTypes.object,
    projectId: PropTypes.string,
    environmentId: PropTypes.string,
    environment: PropTypes.object,
    repoURI: PropTypes.string,
    awaitingCommand: PropTypes.object,
  }),
  lastTick: PropTypes.number,
  onIterationCreate: PropTypes.func,
  isIterationCreating: PropTypes.bool,
  isNewestIteration: PropTypes.bool,
  onIterationCreated: PropTypes.func,
}
