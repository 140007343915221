import { initializeAuth, browserLocalPersistence, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, collection, connectFirestoreEmulator } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import CONFIG from '@/config/config'
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { connectDatabaseEmulator, getDatabase } from 'firebase/database'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

export const COLLECTIONS = {
  users: 'users',
  userRoles: 'userRoles',
  organizations: 'organizations',
  teams: 'teams',
  projects: 'projects',
  projectsViewableIterations: 'viewableIterations',
  projectsViewableIterationsMeta: 'viewableIterationsMeta',
  hints: 'hints',
  jobs: 'gunslingerJobs',
  iterationFeedback: 'iterationFeedback',
}

export const firebaseApp = initializeApp(CONFIG.firebase)
export const firestoreDb = getFirestore(firebaseApp)
export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
})

export const functions = getFunctions(firebaseApp)

// Connect to emulators if running locally
// if (window.location.hostname === 'localhost') {
//   connectAuthEmulator(auth, 'http://localhost:9099')
//   connectFirestoreEmulator(firestoreDb, 'localhost', 8080)
//   connectFunctionsEmulator(functions, 'localhost', 5001)
//   const database = getDatabase(firebaseApp)
//   connectDatabaseEmulator(database, 'localhost', 9000)
//   const storage = getStorage(firebaseApp)
//   connectStorageEmulator(storage, 'localhost', 9199)
// }

export const doesUserExistFirebaseFunction = httpsCallable(functions, 'doesUserExist')
export const createIterationForProject = httpsCallable(functions, 'createIterationForProject')
export const createOrganization = httpsCallable(functions, 'createOrganization')

export const getIteration = httpsCallable(functions, 'getIteration')
export const getHintsForProject = httpsCallable(functions, 'getHintsForProject')
export const updateHintFirebaseFunction = httpsCallable(functions, 'updateHint')
export const createHintFirebaseFunction = httpsCallable(functions, 'createHint')
export const deleteHintFirebaseFunction = httpsCallable(functions, 'deleteHint')

export const createIterationCommandFirebaseFunction = httpsCallable(
  functions,
  'createIterationCommand'
)

export const removeAllIterationCommandsFirebaseFunction = httpsCallable(
  functions,
  'removeAllIterationCommands'
)

export const stopVMJobForIterationFirebaseFunction = httpsCallable(
  functions,
  'stopVMJobForIteration'
)
export const createIterationFeedbackFirebaseFunction = httpsCallable(
  functions,
  'createIterationFeedback'
)
export const createProjectFirebaseFunction = httpsCallable(functions, 'createProject')

export const usersCollectionRef = collection(firestoreDb, COLLECTIONS.users)
export const userRolesCollectionRef = collection(firestoreDb, COLLECTIONS.userRoles)
export const organizationsCollectionRef = collection(firestoreDb, COLLECTIONS.organizations)
export const teamsCollectionRef = collection(firestoreDb, COLLECTIONS.teams)
export const projectsCollectionRef = collection(firestoreDb, COLLECTIONS.projects)
export const hintsCollectionRef = collection(firestoreDb, COLLECTIONS.hints)
export const jobsCollectionRef = collection(firestoreDb, COLLECTIONS.jobs)
export const iterationFeedbackCollectionRef = collection(firestoreDb, COLLECTIONS.iterationFeedback)
