import { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Dropdown,
  DropdownButton,
  DropdownDescription,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '@/components/catalyst/dropdown'
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/catalyst/table'

import useStore from '@/stores/useStore'

function signularOrPlural(count, singular, plural) {
  if (count === 1) {
    return singular
  }
  return plural
}

export default function OTeamsTable({ teams }) {
  const getProjectCountsForTeams = useStore(state => state.getProjectCountsForTeams)
  const projects = useStore(state => state.projects)
  const projectCountsForTeams = useMemo(() => {
    if (teams && teams.length > 0 && projects && projects.length > 0) {
      const teamsIds = teams.map(team => team.id)
      return getProjectCountsForTeams(teamsIds)
    }
    return null
  }, [teams, getProjectCountsForTeams, projects])

  return (
    <Table grid className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Project count</TableHeader>
          <TableHeader>Your access</TableHeader>
          <TableHeader>Actions</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {teams?.map(team => (
          <TableRow key={team.id} href={`/teams/${team.id}`} title={team.name}>
            <TableCell>
              <div className="flex items-center gap-4">
                <div className="font-medium">{team.name}</div>
              </div>
            </TableCell>
            <TableCell className="text-zinc-500">
              {projectCountsForTeams?.[team.id]}{' '}
              {signularOrPlural(projectCountsForTeams?.[team.id], 'project', 'projects')}
            </TableCell>
            <TableCell className="text-zinc-500">Manager</TableCell>

            <TableCell className="w-64">
              <Dropdown>
                <DropdownButton outline>
                  Actions
                  <ChevronDownIcon />
                </DropdownButton>
                <DropdownMenu>
                  <DropdownItem href={`/teams/${team.id}`}>
                    <DropdownLabel>See details</DropdownLabel>
                    <DropdownDescription>
                      See all projects from that team and manage users.
                    </DropdownDescription>
                  </DropdownItem>
                  <DropdownItem onClick={() => alert('Not implemented yet')}>
                    <DropdownLabel>Manage users</DropdownLabel>
                    <DropdownDescription>See who has access and add new users</DropdownDescription>
                  </DropdownItem>
                  <DropdownItem onClick={() => alert('Not implemented yet')}>
                    <DropdownLabel>Edit details</DropdownLabel>
                    <DropdownDescription>Edit name and other metainformation</DropdownDescription>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
OTeamsTable.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
}
