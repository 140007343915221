import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { CommandLineIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'
import AActionContentTerminal from '@/components/actions/components/AActionContentTerminal.jsx'

export default function RunCommandAction({ action, isLast = false }) {
  //:TODO: Fix display of command in the top content in the details
  const terminalOutputs = []
  let detailsContent = `## ACTION:\n${action?.args?.comment}\n\n## COMMAND:\n${action?.args?.command || action?.args?.cmd}\n\n`

  const outputs = action?.outputs
  if (Array.isArray(outputs)) {
    detailsContent += '## OUTPUTS:\n'
    outputs?.forEach(output => {
      if (typeof output === 'object' && output !== null) {
        const outputEntries = Object.entries(output).map(([key, value]) => `${key}: ${value}`)
        outputEntries.forEach(entry => {
          detailsContent += `- ${entry}\n`
          terminalOutputs.push(`- ${entry}\n`)
        })
      }
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={detailsContent}
      terminalOutputs={terminalOutputs}
      actionIcon={CommandLineIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Run command" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={action?.args?.comment} />
        <AActionContentTerminal
          content={'$ ' + action?.args?.cmd}
          terminalOutputs={terminalOutputs}
        />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

RunCommandAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
