import { useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@/components/catalyst/button'
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '@/components/catalyst/dialog'

import { ITERATION_COMMANDS } from '@/const/const'

import { removeAllIterationCommandsFirebaseFunction } from '@/services/Firebase'

import { useToast } from '@/components/ui/use-toast'
import { Progress } from '@/components/ui/progress'

export default function MIterationStatusChangeAwaiting({
  iterationId,
  isOpen = false,
  handleClose = () => {},
  awaitingCommandMeta = {},
}) {
  const [isDeleting, setIsDeleting] = useState(false)
  const [progressValue, setProgressValue] = useState(5)
  const { toast } = useToast()
  const handleDeleteAllCommands = useCallback(() => {
    setIsDeleting(true)
    removeAllIterationCommandsFirebaseFunction({ iterationId })
      .then(() => {
        // setIsDeleting(false)
        toast({
          title: 'Command cancelled successfully 🎉',
          description: 'System needs a few seconds to update the status. Please wait.',
        })
      })
      .catch(error => {
        setIsDeleting(false)
        console.error(error)
        toast({
          variant: 'destructive',
          title: 'Failed to cancel command',
          description: 'Check console for details and try again.',
        })
      })
  }, [iterationId, toast])

  const isDuringTransition = awaitingCommandMeta?.active
  const isRollingBack = awaitingCommandMeta?.args?.rollback

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressValue(prev => {
        if (prev < 100) return prev + 1
        else return 50
      })
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (isDuringTransition) {
      setProgressValue(65)
    }
  }, [isDuringTransition])

  const [title, description, userSuggestion] = useMemo(() => {
    let userSuggestion = awaitingCommandMeta?.args?.suggestion || null

    const pausingOrResuming =
      awaitingCommandMeta?.command === ITERATION_COMMANDS.PAUSE ? 'Pausing' : 'Resuming'

    if (isRollingBack && isDuringTransition) {
      return [
        'Iteration rollback in progress',
        'System is actively rolling back the iteration from the specified point. Please wait a few seconds.',
        userSuggestion,
      ]
    } else if (isRollingBack) {
      return [
        'Iteration rollback queued',
        'This may take from a few seconds to about 2 minutes. Hang tight!',
        userSuggestion,
      ]
    } else if (isDuringTransition) {
      return [
        `${pausingOrResuming} operation in progress`,
        'System is actively changing the status of the iteration. Please wait a few seconds.',
        userSuggestion,
      ]
    }

    return [
      `${pausingOrResuming} operation queued`,
      'This may take from a few seconds to about 2 minutes. Hang tight!',
    ]
  }, [awaitingCommandMeta, isRollingBack, isDuringTransition])

  const buttonLabel = useMemo(() => {
    if (isDeleting) {
      return 'Working...'
    } else if (isDuringTransition) {
      return 'It\'s too late to cancel now, our system is working...'
    } else if (isRollingBack) {
      return 'Cancel rollback'
    } else {
      return 'Cancel status change'
    }
  }, [isDuringTransition, isRollingBack, isDeleting])

  if (!isOpen) return null

  return (
    <Dialog key="transition-dialog" size="3xl" open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <div className="pb-1 text-2xl">{title}</div>
      </DialogTitle>
      <DialogDescription>{description}</DialogDescription>
      <DialogBody>
        <div className="space-y-8">
          {userSuggestion && (
            <div className="pt-8">
              <h3 className="pb-2 font-mono text-sm font-bold text-zinc-700">Your suggestion</h3>
              <p className="rounded-md bg-amber-300 p-4 text-base font-semibold text-zinc-700">
                {userSuggestion}
              </p>
            </div>
          )}
          <Progress value={progressValue} />
        </div>
      </DialogBody>
      <DialogActions>
        <Button
          disabled={isDuringTransition || isDeleting}
          plain={isDuringTransition}
          color={isDuringTransition ? null : 'red'}
          onClick={handleDeleteAllCommands}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MIterationStatusChangeAwaiting.propTypes = {
  iterationId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  awaitingCommandMeta: PropTypes.shape({
    active: PropTypes.bool,
    args: PropTypes.shape({
      rollback: PropTypes.object,
      suggestion: PropTypes.string,
    }),
  }),
}
