import PropTypes from 'prop-types'
import MActionListItem from '@/components/molecules/iteration-details/MActionListItem.jsx'
import { actionShape } from '@/components/propTypes.js'

/** @typedef {{
   content: string,
   title: string,
   detailsContent: string,
 }} ActionResultsDisplay */

export default function MActionsList({ actions = [] }) {
  return (
    <ul role="list" className="space-y-6">
      {actions?.map((action, index) => (
        <MActionListItem
          key={`${action.actionId}-${action?.outputs?.length || 0}`}
          action={action}
          isLast={index === actions.length - 1}
        />
      ))}
    </ul>
  )
}

MActionsList.propTypes = {
  //make array of action shapes
  actions: PropTypes.arrayOf(actionShape),
}
