import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import _ from 'lodash'
import { Avatar } from '@/components/catalyst/avatar'
import { Badge } from '@/components/catalyst/badge'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/catalyst/table'
import { PROJECT_STATUSES_LABELS, getStatusColor, PROJECT_STATUSES } from '@/const/const'

import useStore from '@/stores/useStore'

const MINUTE_IN_MS = 60000

export default function OProjectsTable({ projects }) {
  const [lastTick, setLastTick] = useState(Date.now())
  const getTeamsByIds = useStore(state => state.getTeamsByIds)
  const teams = useStore(state => state.teams)

  const teamsOfProjects = useMemo(() => {
    if (teams && projects) {
      const teamsIds = _.uniq(projects?.map(project => project.teamId))
      const teamsObj = getTeamsByIds(teamsIds)

      return teamsObj
    }
    return {}
  }, [projects, getTeamsByIds, teams])

  useEffect(() => {
    const interval = setInterval(() => {
      setLastTick(Date.now())
    }, MINUTE_IN_MS)
    return () => clearInterval(interval)
  }, [])

  const projectsWithRelativeTime = useMemo(() => {
    // lastTick is used to force a re-render every minute
    if (lastTick && projects) {
      return projects?.map(project => ({
        ...project,
        updatedAtRelative: DateTime.fromJSDate(
          project?.updatedAt?.toDate() || Date.now()
        ).toRelative(),
      }))
    }
  }, [projects, lastTick])

  return (
    <Table grid className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Team</TableHeader>
          <TableHeader>Updated</TableHeader>
          <TableHeader>Status</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {projectsWithRelativeTime?.map(project => (
          <TableRow key={project.id} href={`/projects/${project.id}`} title={project.name}>
            <TableCell>
              <div className="flex items-center gap-4">
                <Avatar
                  src={
                    project.image ? `/project-covers/${project.image}.svg` : `/project-covers/7.svg`
                  }
                  className="size-12"
                />
                <div>
                  <div className="font-medium">{project.name}</div>
                </div>
              </div>
            </TableCell>
            <TableCell className="text-zinc-500">
              {teamsOfProjects?.[project.teamId]?.name || 'Loading...'}
            </TableCell>
            <TableCell className="text-mimirGray-500">{project.updatedAtRelative}</TableCell>
            <TableCell className="w-64">
              <Badge
                className={`${project.status === PROJECT_STATUSES.IN_PROGRESS && 'animate-pulse'}`}
                color={getStatusColor(project.status)}
              >
                {PROJECT_STATUSES_LABELS[project.status]}
              </Badge>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
OProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      image: PropTypes.string,
    })
  ),
}
