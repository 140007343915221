import PropTypes from 'prop-types'
import GenericAction from '@/components/actions/GenericAction.jsx'
import { SparklesIcon } from '@heroicons/react/24/solid/index.js'
import { actionShape } from '@/components/propTypes.js'
import { insertNewlinesIfNeeded } from '@/components/actions/utils.js'
import AContentTitle from '@/components/actions/components/AContentTitle.jsx'
import AActionContentGenericLayout from '@/components/actions/components/AActionContentGenericLayout.jsx'
import AActionContentDescription from '@/components/actions/components/AActionContentDescription.jsx'

export default function StepDoneAction({ action, isLast = false }) {
  let report = action.args?.report
  let artifacts = action.args?.artifacts
  let artifactsSplit = ''
  if (Array.isArray(artifacts)) {
    artifactsSplit = artifacts
  } else if (typeof artifacts === 'string') {
    artifactsSplit = artifacts?.split(',')
  } else if (typeof artifacts === 'object' && artifacts !== null) {
    artifactsSplit = Object.entries(artifacts).map(([key, value]) => `${key}: ${value}`)
  }
  const content = [report]
  if (artifactsSplit) {
    artifactsSplit.forEach(artItem => {
      content.push(artItem)
    })
  }

  return (
    <GenericAction
      action={action}
      detailsContent={`## REPORT:\n${insertNewlinesIfNeeded(report)}\n\n## Artifacts:\n${insertNewlinesIfNeeded(artifacts)}\n`}
      actionIcon={SparklesIcon}
      isLast={isLast}
    >
      <AContentTitle action={action} title="Summarize & commit" />
      <AActionContentGenericLayout>
        <AActionContentDescription content={content || action.name} />
      </AActionContentGenericLayout>
    </GenericAction>
  )
}

StepDoneAction.propTypes = {
  action: actionShape,
  isLast: PropTypes.bool,
}
