import { useCallback, useMemo, useState } from 'react'
import _ from 'lodash'
import { ACTION_STATUSES } from '@/const/const.js'
import MRestartFromAction from '@/components/molecules/iteration-details/MRestartFromAction.jsx'
import classNames from 'classnames'
import { InformationCircleIcon, PencilSquareIcon } from '@heroicons/react/24/solid/index.js'
import { Button } from '@/components/catalyst/button.jsx'
import { GitCompareIcon, InfoIcon } from 'lucide-react'
import { convertGitHubSSHToHTTPS, hasValidCommitHashes } from '@/lib/git-utils.js'
import MActionDetailsInspector from '@/components/molecules/iteration-details/MActionDetailsInspector.jsx'
import OLLMPlaygroundDialog from '@/components/organisms/OLLMPlaygroundDialog.jsx'
import PropTypes from 'prop-types'
import { actionShape } from '@/components/propTypes.js'
import ActionStatus from '@/components/actions/components/ActionStatus.jsx'

import { analyticsTrackEvent, ANALYTIC_EVENTS } from '@/services/Analytics'

export default function GenericAction({
  action,
  title,
  detailsContent,
  actionIcon,
  customOptions = {},
  isLast = false,
  children,
}) {
  const [isJSONViewOpen, setIsJSONViewOpen] = useState(false)
  const [isLLMPlaygrounOpen, setIsLLMPlaygrounOpen] = useState(false)

  const actionDataToDisplayOnHover = useMemo(() => {
    return _.omit(action, [
      // 'actionId',
      // 'elementId',
      // 'stepId',
      // 'iterationId',
      'projectId',
      'index',
      'status',
      'updatedAt',
      'createdAt',
      'startedAt',
      'endedAt',
      'updatedAtRelative',
      'shouldBeDeleted',
    ])
  }, [action])

  const shouldAllowLLMPlayground = useMemo(() => {
    return action?.type === 'llm' && action?.subtype === 'request'
  }, [action])

  const ActionIcon = useMemo(() => {
    return actionIcon ?? InformationCircleIcon
  }, [actionIcon])

  const handleShowDetails = useCallback(() => {
    if (!isJSONViewOpen) {
      analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_ACTION_DETAILS, {
        iterationId: action?.iterationId,
        elementIndex: action?.elementIndex,
        stepIndex: action?.stepIndex,
        actionIndex: action?.index,
        actionType: action.type,
        actionName: action.name,
        status: action.status,
      })
    }
    setIsJSONViewOpen(true)
  }, [isJSONViewOpen, action])

  return (
    <>
      <li
        key={action.actionId}
        className="relative flex w-full max-w-full flex-col gap-x-4 transition delay-150 ease-in-out "
      >
        <div className="mb-4 ml-3 mt-1 pr-12">
          <MRestartFromAction action={action} />
        </div>
        <div
          className={classNames(
            isLast ? 'h-16' : '-bottom-6',
            'absolute left-0 top-0 flex w-6 justify-center '
          )}
        >
          <div className="w-px bg-gray-200" />
        </div>

        <div className="flex w-full items-center">
          <ActionStatus status={action.status} />

          <div className="flex flex-grow flex-col items-start justify-start pl-2 pr-2 text-xs leading-5 text-zinc-500  ">
            <div className="flex w-full flex-grow">
              <ActionIcon
                className={classNames(
                  action.status === ACTION_STATUSES.RUNNING
                    ? 'text-sky-400'
                    : customOptions.iconStyle ?? 'text-zinc-900',

                  'mr-2 mt-0 h-5 w-5 flex-shrink-0 flex-grow-0'
                )}
                aria-hidden="true"
              />
              <div
                className={classNames(
                  'flex w-full flex-1 flex-col rounded-md p-3 font-bold shadow-inner',
                  customOptions.actionBodyStyle ?? 'bg-zinc-100 text-zinc-900'
                )}
              >
                {children}
              </div>
            </div>
          </div>
          {shouldAllowLLMPlayground && (
            <Button
              onClick={() => {
                setIsLLMPlaygrounOpen(true)
              }}
              plain
              className="place-self-start hover:cursor-pointer"
            >
              <PencilSquareIcon className="" />
            </Button>
          )}
          <div className=" flex flex-col place-self-start  ">
            <Button
              onClick={handleShowDetails}
              plain
              className="mb-1 place-self-start hover:cursor-pointer"
            >
              {/* <InformationCircleIcon className="" /> */}
              <InfoIcon strokeWidth={3} className="h-6 w-4 text-zinc-600" />
            </Button>
            {hasValidCommitHashes(action) && (
              <Button
                href={`${convertGitHubSSHToHTTPS(action?.repoURI)}/compare/${action?.startCommitHash}...${action?.endCommitHash}`}
                target="_blank"
                plain
                className="place-self-start hover:cursor-pointer"
                onClick={() => {
                  analyticsTrackEvent(ANALYTIC_EVENTS.ITERATION_CLICK_REPO_CHANGES_STEP_ACTION, {
                    iterationId: action?.iterationId,
                    elementIndex: action?.elementIndex,
                    stepIndex: action?.stepIndex,
                    actionIndex: action?.index,
                    actionType: action.type,
                    actionName: action.name,
                    status: action.status,
                  })
                }}
              >
                {/* <CodeBracketSquareIcon className="" /> */}
                <GitCompareIcon strokeWidth={3} className=" h-6 w-4 text-zinc-600" />
              </Button>
            )}
          </div>
        </div>
        {isJSONViewOpen && (
          <MActionDetailsInspector
            title="Action details"
            content={children}
            isOpen={isJSONViewOpen}
            setIsOpen={setIsJSONViewOpen}
            code={detailsContent}
            language="markdown"
            actionObject={actionDataToDisplayOnHover}
          />
        )}
        {isLLMPlaygrounOpen && (
          <OLLMPlaygroundDialog
            isOpen={isLLMPlaygrounOpen}
            title={title}
            description={children}
            setIsOpen={setIsLLMPlaygrounOpen}
            messages={action?.args?.messages}
            tools={action?.args?.tools}
          />
        )}
      </li>
    </>
  )
}

GenericAction.propTypes = {
  action: actionShape,
  title: PropTypes.string,
  detailsContent: PropTypes.string,
  actionIcon: PropTypes.elementType,
  customOptions: PropTypes.shape({
    iconStyle: PropTypes.string,
    actionBodyStyle: PropTypes.string,
  }),
  isLast: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
