export function convertGitHubSSHToHTTPS(sshUrl) {
  const urlWithoutPrefix = sshUrl.replace('git@github.com:', '')
  const urlWithoutSuffix = urlWithoutPrefix.replace('.git', '')

  return `https://github.com/${urlWithoutSuffix}`
}

export function hasValidBranch({ branchName } = { branchName: null }) {
  if (branchName == null) {
    return false
  }
  // Check if the branch name contains any invalid characters or patterns
  const invalidPatterns = [
    /^\s*$/, // Empty string
    /\.\./, // Double dot
    /[\\ ~^:?*[\]]/, // Invalid characters
    /\/$/, // Ends with a slash
    /(?:^|\/)\./, // Segment starts with a dot
    /^\-/, // Starts with a dash
    /[\x00-\x20\x7F]/, // ASCII control characters
  ]

  // Test against all invalid patterns
  for (const pattern of invalidPatterns) {
    if (pattern.test(branchName)) {
      return false
    }
  }

  return true
}

function isValidGitCommitHash(hash) {
  if (hash == null) {
    return false
  }
  // Regular expression to match a 40-character hexadecimal string
  const sha1Pattern = /^[a-f0-9]{40}$/i
  return sha1Pattern.test(hash)
}

export function hasValidCommitHashes(
  { startCommitHash, endCommitHash } = { startCommitHash: null, endCommitHash: null }
) {
  return (
    isValidGitCommitHash(startCommitHash) &&
    isValidGitCommitHash(endCommitHash) &&
    startCommitHash !== endCommitHash
  )
}

export function getCommitHashes(actions) {
  let startCommitHash = null
  let endCommitHash = null

  actions.forEach(action => {
    if (
      startCommitHash == null &&
      action.startCommitHash &&
      isValidGitCommitHash(action.startCommitHash)
    ) {
      startCommitHash = action.startCommitHash
    }
    if (action.endCommitHash && isValidGitCommitHash(action.endCommitHash)) {
      endCommitHash = action.endCommitHash
    }
  })

  return { startCommitHash, endCommitHash }
}
